import React, { Fragment } from 'react';

import Confirmation from '../Confirmation';
import ContactInformations from '../ContactInformations';
import InputRequiredNotice from '../InputRequiredNotice';
import ProductInformations from '../ProductInformations';

export default function getSteps({ onValidate, onSubmit }) {
  const confirmationStep = { name: 'Confirmation', children: <Confirmation /> };

  return {
    small: [
      {
        name: 'Votre habitation',
        onValidate,
        children: (
          <Fragment>
            <ProductInformations />
            <InputRequiredNotice />
          </Fragment>
        ),
      },
      {
        name: 'Coordonnées',
        onValidate: onSubmit,
        children: (
          <Fragment>
            <ContactInformations />
            <InputRequiredNotice />
          </Fragment>
        ),
      },
      confirmationStep,
    ],
    large: [
      {
        name: 'Votre habitation',
        onValidate: onSubmit,
        children: (
          <Fragment>
            <ProductInformations />
            <ContactInformations />
            <InputRequiredNotice />
          </Fragment>
        ),
      },
      confirmationStep,
    ],
  };
}
