import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { themes } from 'timeone-components';

import { StepsProvider } from '../components';
import useMedia from '../hooks/useMedia';
import AppContext from './App.context';

const { timeone } = themes;

const MEDIA_QUERIES = {
  large: '(min-width: 1536px)',
  small: '(min-width: 640px)',
};

function App() {
  const matchedMediaQuery = useMedia(Object.values(MEDIA_QUERIES), MEDIA_QUERIES.small);
  const currentMediaConfig = matchedMediaQuery === MEDIA_QUERIES.large ? 'large' : 'small';
  const [currentStep, setCurrentStep] = useState(1);

  return (
    <ThemeProvider theme={{ ...timeone, currentMediaConfig }}>
      <AppContext.Provider
        value={{
          mediaQueries: MEDIA_QUERIES,
          currentMediaConfig,
          currentStep,
          setCurrentStep,
        }}
      >
        <StepsProvider />
      </AppContext.Provider>
    </ThemeProvider>
  );
}
export default App;
