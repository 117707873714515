import insults from '../data/insults';

function testValueWithRegex(value, regex, errorMessage) {
  if (regex.test(value)) {
    return undefined;
  }

  return errorMessage;
}

function isContainingInsult(value) {
  return insults.some(insult => value.includes(insult));
}

export function validateOnlyAlphaNum(value) {
  const regex = /^[a-zA-ZÀ-ÿ0-9\s-]+$/;
  const errorMessage = 'Uniquement des charactères alphanumériques.';

  return testValueWithRegex(value, regex, errorMessage);
}

export function validateOnlyLetters(value) {
  const regex = /^[a-zA-ZÀ-ÿ\s-]+$/;
  const errorMessage = 'Uniquement des lettres.';

  const testValueResult = testValueWithRegex(value, regex, errorMessage);

  if (typeof testValueResult !== 'undefined') {
    return errorMessage;
  }

  return isContainingInsult(value) ? errorMessage : undefined;
}

export function validatePhoneNumber(value) {
  const regex = /^(0|\+33)\d[0-9]{8}$/;
  const errorMessage = 'Uniquement des nombres de forme 0123456789 .';

  return testValueWithRegex(value, regex, errorMessage);
}

export function validateZipCode(value) {
  const regex = /^[0-9]{5}$/;
  const errorMessage = 'Uniquement des chiffres de forme 99999.';

  return testValueWithRegex(value, regex, errorMessage);
}

export function validateEmail(value) {
  const regex = /^[a-z0-9._\-+]+@[a-z0-9.-]{2,}[.][a-z]{2,3}$/i;
  const errorMessage = 'Un mail valide de forme email@email.com .';

  const testValueResult = testValueWithRegex(value, regex, errorMessage);

  if (typeof testValueResult !== 'undefined') {
    return errorMessage;
  }

  if (isContainingInsult(value)) {
    return errorMessage;
  }

  const blackList = [
    'hysterial@live.fr',
    'test',
    'titi',
    'toto',
    'cauquil',
    'companeo.com',
    'lelynx.fr',
    'assurland.com',
    'voila.fr',
  ];

  return blackList.some(item => value.includes(item)) ? errorMessage : undefined;
}
