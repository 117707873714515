import React from 'react';
import PropTypes from 'prop-types';

import AppContext from '../../App/App.context';
import { ButtonsContainer, ButtonSubmit, ButtonBack } from './styledComponents';

const FormButtons = ({ invalid, isLastPage, page, previous, setCurrentStep, submitting, validatingStep }) => {
  const isButtonSubmitDisabled = invalid || submitting || validatingStep;

  if (isLastPage) {
    return null;
  }

  return (
    <ButtonsContainer>
      {page !== 0 ? <ButtonBack onClick={previous} /> : <div />}
      <ButtonSubmit type="submit" onClick={() => setCurrentStep(page + 2)} disabled={isButtonSubmitDisabled}>
        SUIVANT
      </ButtonSubmit>
    </ButtonsContainer>
  );
};

FormButtons.propTypes = {
  invalid: PropTypes.bool.isRequired,
  isLastPage: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  previous: PropTypes.func.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  validatingStep: PropTypes.bool.isRequired,
};

export default props => (
  <AppContext.Consumer>
    {({ setCurrentStep }) => <FormButtons {...props} setCurrentStep={setCurrentStep} />}
  </AppContext.Consumer>
);
