import React from 'react';
import PropTypes from 'prop-types';

import { NoticeContainer, Content, Title } from './styledComponents';

const Notice = ({ content, title, required }) => (
  <NoticeContainer>
    <Title required={required}>{title}</Title>
    <Content>{content}</Content>
  </NoticeContainer>
);

Notice.defaultProps = {
  content: '',
  required: false,
  title: '',
};

Notice.propTypes = {
  content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  required: PropTypes.bool,
  title: PropTypes.string,
};

export default Notice;
