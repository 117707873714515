import React from 'react';
import { InputSelect } from 'timeone-components';
import styled from 'styled-components';
import { Field } from 'react-final-form'; // eslint-disable-line import/no-extraneous-dependencies

export const Label = styled.label`
  appearance: none;
  display: inline-block;
  margin: 0 0 2.5rem;
  position: relative;
  text-align: left;
  width: 100%;

  input {
    width: 100%;
  }

  .select__control,
  .select__control:hover {
    border-bottom: 1px solid #303747;
    padding-top: 0.75rem;

    .select__value-container {
      padding: 0.125rem;
    }
  }

  &::after {
    background: #303747;
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: 25%;
    position: absolute;
    transition: all 0.5s;
    width: 0;
  }

  &.active {
    &::after {
      left: 0;
      width: 100%;
    }
  }
`;

export const LabelSpan = styled.span`
  font-weight: bold;
  left: 0.25rem;
  position: absolute;
  text-transform: capitalize;
  top: 1.25rem;
  transition: all 0.5s cubic-bezier(0.42, 0, 0.18, 1.04);

  &.active {
    font-size: 0.875rem;
    top: 0;
  }

  &:after {
    color: #eb0045;
    content: '${({ required }) => (required ? '*' : '')}';
    padding-left: 0.25rem;
  }
`;

export const LabelError = styled.span`
  color: #eb0045;
  font-size: 0.875rem;
  left: 0.25rem;
  position: absolute;
  top: 3.5rem;
  transition: all 0.25s;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
  }
`;

export default function renderInput(Input) {
  function requiredTest(value) {
    const isRequiredMessage = 'Le champ doit contenir une valeur.';

    let testResult = null;

    if (Input === InputSelect) {
      testResult =
        !(
          !!value &&
          !Array.isArray(value) &&
          Object.keys(value).length === 2 &&
          Object.prototype.hasOwnProperty.call(value, 'label') &&
          Object.prototype.hasOwnProperty.call(value, 'value')
        ) && isRequiredMessage;
    } else {
      testResult = !(!!value && value.length > 0) && isRequiredMessage;
    }

    return testResult;
  }

  class CustomInput extends Input {
    handleValidate = value => {
      const { validation, required } = this.props;

      const requiredTestResult = requiredTest(value);

      if (required && requiredTestResult) {
        return requiredTestResult || undefined;
      }

      return validation ? validation(value) : undefined;
    };

    render() {
      const { change, className, name, label, required } = this.props;

      return (
        <Field name={name} subscription={{ touched: true, active: true, data: true, initial: true, error: true }}>
          {({ meta }) => (
            <Field name={name} validate={this.handleValidate}>
              {({ input }) => (
                <Label className={`${className}${this.handleIsActive(input, meta) ? ' active' : ''}`} htmlFor={name}>
                  <LabelSpan required={required} className={this.handleIsActive(input, meta) ? 'active' : null}>
                    {label}
                  </LabelSpan>
                  {change && change(input.onChange)}
                  {this.inputRender(input, meta)}
                  {<LabelError className={this.handleIsInValide(meta) ? 'visible' : 'hidden'}>{meta.error}</LabelError>}
                </Label>
              )}
            </Field>
          )}
        </Field>
      );
    }
  }

  return CustomInput;
}
