import React from 'react';
import PropTypes from 'prop-types';

import { ConfirmationContainer, IconCheck } from './styledComponents';

const Confirmation = ({ className }) => (
  <ConfirmationContainer className={className}>
    <p>Nous avons bien enregistré les informations que vous nous avez transmises</p>
    <IconCheck />
    <p>
      Un Conseiller Allianz, proche de chez vous, vous recontactera prochainement pour vous proposer un devis
      personnalisé.
    </p>
  </ConfirmationContainer>
);

Confirmation.defaultProps = {
  className: '',
};

Confirmation.propTypes = {
  className: PropTypes.string,
};

export default Confirmation;
