import React, { useState } from 'react';

import { ConfirmationStep, Wizard } from './styledComponents';
import AppContext from '../../App/App.context';
import FormButtons from '../FormButtons';
import getSteps from './getSteps';
import Header from '../Header';
import sendFormValues from '../../api/form';

const StepsProvider = ({ currentMediaConfig, currentStep }) => {
  const [formValues, setFormValues] = useState({});
  const [shouldDisplayConfirmation, setShouldDisplayConfirmation] = useState(false);
  const onValidate = values => {
    const formattedValues = {};

    function filterValues([key, value]) {
      if (value.value) {
        formattedValues[key] = value.value;
      } else {
        formattedValues[key] = value;
      }
    }

    Object.entries(values).map(filterValues);

    setFormValues({ ...formValues, ...formattedValues });
  };
  const onSubmit = values => {
    onValidate(values);

    setShouldDisplayConfirmation(true);

    sendFormValues(values);
  };
  const steps = getSteps({ onValidate, onSubmit })[currentMediaConfig];

  if (shouldDisplayConfirmation) {
    return (
      <div>
        <Header page={steps.length - 1} stepsName={steps.map(({ name }) => name)} />
        <ConfirmationStep />
      </div>
    );
  }

  return (
    <Wizard
      currentStep={currentStep}
      onSubmit={onSubmit}
      renderButtons={buttonsProps => (
        <FormButtons {...buttonsProps} isLastPage={shouldDisplayConfirmation} steps={steps} />
      )}
      renderHeader={Header}
      steps={steps}
    >
      {steps.map(({ name, children }) => (
        <Wizard.Page key={name}>{children}</Wizard.Page>
      ))}
    </Wizard>
  );
};

export default props => (
  <AppContext.Consumer>{context => <StepsProvider {...props} {...context} />}</AppContext.Consumer>
);
