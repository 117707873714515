import React from 'react';
import styled from 'styled-components';

const InputRequiredNotice = styled(({ className }) => <span className={className}>* champs obligatoires</span>)`
  color: #e21d42;
  display: block;
  font-size: 0.875rem;
  margin: 1.5rem 0 3.5rem;
  text-align: right;
  width: 100%;
`;

export default InputRequiredNotice;
