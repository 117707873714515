import { Wizard as TCWizard } from 'timeone-components';
import styled, { css } from 'styled-components';

import Confirmation from '../Confirmation';

export const ConfirmationStep = styled(Confirmation)`
  ${({ theme }) =>
    theme.currentMediaConfig === 'large'
      ? css`
          margin: 4rem 8rem 2rem;
        `
      : css`
          margin: 4rem 2rem 2rem;
        `}
`;

export const Wizard = styled(TCWizard)`
  ${({ theme }) =>
    theme.currentMediaConfig === 'large'
      ? css`
          display: -ms-grid;
          display: grid;
          -ms-grid-rows: auto;
          -ms-grid-columns: 1fr 1fr 0.5fr 1fr 1fr;
          grid-template-columns: repeat(2, 1fr) 0.5fr repeat(2, 1fr);
          margin: 2rem 8rem;

          & > * {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(10) {
              -ms-grid-column: 1;
              -ms-grid-column-span: 6;
              grid-column: 1 / 6;
            }

            &:nth-child(3),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(8),
            &:nth-child(11) {
              -ms-grid-column: 1;
              -ms-grid-column-span: 2;
              grid-column: 1 / 3;
            }

            &:nth-child(4),
            &:nth-child(7),
            &:nth-child(9),
            &:nth-child(13) {
              -ms-grid-column: 4;
              -ms-grid-column-span: 2;
              grid-column: 4 / 6;
            }

            &:nth-child(12) {
              -ms-grid-column: 3;
              margin: 1.5rem 0 0;
              text-align: center;
            }

            &:nth-child(14) {
              -ms-grid-column: 5;
              -ms-grid-row: 6;
              grid-column: 1 / 6;
            }

            &:nth-child(15) {
              -ms-grid-column: 5;
              -ms-grid-row: 7;
              grid-column: 5 / 6;
            }
          }
        `
      : css`
          margin: 4rem 2rem 2rem;
        `}
`;
