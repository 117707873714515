import { useState, useEffect } from 'react';

function useMedia(mediaQueries, defaultValue) {
  const mediaQueryList = mediaQueries.map(query => window.matchMedia(query));
  const getValue = () => {
    const index = mediaQueryList.findIndex(mql => mql.matches);

    return typeof mediaQueries[index] !== 'undefined' ? mediaQueries[index] : defaultValue;
  };

  const [value, setValue] = useState(getValue);

  useEffect(() => {
    const handler = () => setValue(getValue);

    mediaQueryList.forEach(mql => mql.addListener(handler));

    return () => mediaQueryList.forEach(mql => mql.removeListener(handler));
  }, []);

  return value;
}

export default useMedia;
