import React from 'react';
import styled from 'styled-components';

import HouseImg from '../../assets/house.png';

export const HeaderContainer = styled.header`
  background-color: #deffd1;
  height: ${({ theme }) => (theme.currentMediaConfig === 'large' ? '16.25rem' : '14rem')};
  padding: 2rem 0 0 0;
  text-align: center;
`;

export const StepIndicator = styled.span`
  color: #007ab3;
  font-weight: 600;
  margin: 0 0.5rem 0 0;
`;

export const StepIndicatorRounded = styled.div`
  background-color: #fff;
  border: 0.125rem solid #007ab3;
  border-radius: 50%;
  height: 1rem;
  position: relative;
  top: 0.125rem;
  width: 1rem;

  &:first-child {
    margin: 0;
  }

  &::after {
    content: '${({ content }) => content}';
    position: absolute;
    top: 1.25rem;
  }
`;

export const MainTitle = styled.h1`
  margin: 1.875rem 0 0;
  text-transform: none;
`;

export const Image = styled(({ className }) => <img className={className} src={HouseImg} alt="house" />)`
  margin: 2rem 0 0;
  width: ${({ theme }) => (theme.currentMediaConfig === 'large' ? '16rem' : '8rem')};
`;
