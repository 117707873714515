import React, { Fragment } from 'react';

import AppContext from '../../App/App.context';
import { validateEmail, validatePhoneNumber } from '../../utils/validations';
import NameInputs from '../NameInputs';
import Notice from '../Notice';
import { LabelEt, InputWithoutRequiredIndicator } from './styledComponents';

const ContactInformations = () => (
  <AppContext.Consumer>
    {({ currentMediaConfig }) => (
      <Fragment>
        {currentMediaConfig !== 'large' && <NameInputs />}
        <Notice
          title="Coordonnées de contact"
          content="Nous les utiliserons uniquement pour vous recontacter dans le cadre de votre projet."
          required
        />
        <InputWithoutRequiredIndicator
          name="phoneNumber"
          label="Téléphone Mobile"
          type="tel"
          validation={validatePhoneNumber}
          required
        />
        <LabelEt>et</LabelEt>
        <InputWithoutRequiredIndicator name="email" label="Email" type="email" validation={validateEmail} required />
      </Fragment>
    )}
  </AppContext.Consumer>
);

export default ContactInformations;
