import React, { Fragment, PureComponent } from 'react';

import AppContext from '../../App/App.context';
import { validateZipCode } from '../../utils/validations';
import Introduction from '../Introduction';
import Input from '../Input';
import NameInputs from '../NameInputs';
import Select from '../Select';

class ProductInformations extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cityOptions: [],
    };
  }

  loadZipCodeOptions = async event => {
    const { value } = event.target;

    if (!validateZipCode(value)) {
      const response = await fetch(`https://validator.variousad.com/get/generic_city/FR/${value}`);
      const cities = await response.json();

      if (!cities.message) {
        this.setState({
          cityOptions: cities.map(({ label }) => ({ label, value: label })),
        });
      }
    }
  };

  formatOptions = data => data.map(name => ({ label: name, value: name }));

  render() {
    const { cityOptions } = this.state;

    return (
      <AppContext.Consumer>
        {({ currentMediaConfig }) => (
          <Fragment>
            <Introduction />
            <h4 style={{ margin: '1.5rem 0 1rem', fontSize: '1.25rem' }}>Mon Projet :</h4>
            <Select
              name="requestSubject"
              label="Ma demande concerne"
              options={this.formatOptions([
                'Résidence principale',
                'Résidence secondaire',
                'Logement étudiant',
                'Résidence non occupée',
              ])}
              isSearchable
              required
            />
            <Select
              name="kindOfGood"
              label="Type de bien"
              options={this.formatOptions(['Appartement', 'Maison'])}
              isSearchable
              required
            />
            <Select
              name="roomsNumber"
              label="Nombre de pièces à assurer"
              options={this.formatOptions(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10 et +'])}
              isSearchable
              required
            />
            {currentMediaConfig === 'large' && <NameInputs />}
            <Input
              name="zipCode"
              label="Code Postal"
              onChange={this.loadZipCodeOptions}
              validation={validateZipCode}
              required
            />
            <Select
              change={cityOptions.length === 1 ? change => change({ target: { value: cityOptions[0] } }) : undefined}
              name="city"
              label="Ville"
              options={cityOptions}
              required
            />
          </Fragment>
        )}
      </AppContext.Consumer>
    );
  }
}

export default ProductInformations;
