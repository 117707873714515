import React from 'react';

import { CallToAction, IntroductionContainer, Title, Ast, Container, Mention } from './styledComponents';

const Introduction = () => (
  <IntroductionContainer>
    <Title>Tarificateur en ligne indisponible en ce moment.</Title>
    <Container>
      <p>
        Vous pouvez contacter un Conseiller au 0 805 50 20 20<Ast>*</Ast> du lundi au vendredi de 9h à 20h, le samedi de
        9h à 17h.
      </p>
      <Mention>
        <Ast>*</Ast> Appel gratuit depuis un poste fixe en France Métropolitaine et selon opérateur depuis un mobile.
      </Mention>
    </Container>
    <CallToAction>Afin qu’un conseiller proche de chez vous vous rappelle, merci de remplir le formulaire ci-dessous :</CallToAction>
  </IntroductionContainer>
);

Introduction.defaultProps = {};

Introduction.propTypes = {};

export default Introduction;
