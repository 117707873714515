import styled from 'styled-components';

export const IntroductionContainer = styled.div`
  margin: 1rem 0 0;
  text-align: left;

  p {
    font-style: italic;
    opacity: 0.875;
  }
`;

export const Title = styled.h4`
  font-size: 1.25rem;
`;

export const Container = styled.div`
  margin: 1rem 0;
`;

export const Ast = styled.span`
  color: #eb0045;
  opacity: 0.875;
`;

export const Mention = styled.span`
  font-size: 0.75rem;
  opacity: 0.875;
`;

export const CallToAction = styled.p`
  margin: 2rem 0 0;
`;
