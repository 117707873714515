import React from 'react';
import PropTypes from 'prop-types';

import {
  CheckIcon,
  Filler,
  ProgressBarContainer,
  StepIndicatorRounded,
  StepIndicatorsContainer,
} from './styledComponents';

const ProgressBar = ({ percentage, steps }) => (
  <ProgressBarContainer steps={steps}>
    <Filler percentage={percentage} />
    <StepIndicatorsContainer steps={steps}>
      {steps.map((stepName, index) => {
        const isChecked = ((index + 1) / steps.length) * 100 < percentage;

        return (
          <StepIndicatorRounded key={stepName} className={isChecked ? 'checked' : ''} content={stepName}>
            <CheckIcon />
          </StepIndicatorRounded>
        );
      })}
    </StepIndicatorsContainer>
  </ProgressBarContainer>
);

ProgressBar.defaultProps = {
  percentage: 0,
  steps: [{ name: '' }],
};

ProgressBar.propTypes = {
  percentage: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.string),
};

export default ProgressBar;
