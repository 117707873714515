export default [
  'abruti',
  'batard',
  'bâtard',
  'bite',
  'bouffon',
  'bougnoul',
  'branleur',
  'con',
  'connard',
  'conard',
  'connasse',
  'conasse',
  'couille',
  'crétin',
  'cretin',
  'ducon',
  'enculé',
  'enculer',
  'enculeur',
  'enfoiré',
  'fiotte',
  'fdp',
  'foutre',
  'garce',
  'gouine',
  'grognasse',
  'imbécile',
  'imbecile',
  'lopette',
  'merde',
  'négro',
  'nègre',
  'negre',
  'ntm',
  'pédale',
  'pedale',
  'pédé',
  'pétasse',
  'petasse',
  'pouffiasse',
  'poufiasse',
  'putain',
  'pute',
  'salaud',
  'salop',
  'tafiole',
  'tantouze',
  'tapette',
  'tarlouze',
  'teub',
  'tocard',
  'trouduc',
  'vtf',
  'zguègue',
  'zguegue',
];
