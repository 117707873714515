import React from 'react';

export const ArrowBack = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="38" height="24" viewBox="0 0 38 24" {...props}>
    <defs>
      <path
        id="arrow-left-a"
        d="M35.6247031,9.60035999 L8.10451306,9.60035999 L13.5510689,4.09729757 C14.47981,3.15892103 14.47981,1.64215895 13.5510689,0.703782405 C12.6223278,-0.234594135 11.1211401,-0.234594135 10.192399,0.703782405 L0.693586698,10.3011425 C0.472684086,10.521937 0.299287411,10.7883303 0.178147268,11.0835229 C-0.0593824228,11.6691083 -0.0593824228,12.3314917 0.178147268,12.9170771 C0.299287411,13.2122697 0.472684086,13.478663 0.693586698,13.6994575 L10.192399,23.2968176 C10.6555819,23.7648059 11.263658,24 11.871734,24 C12.47981,24 13.087886,23.7648059 13.5510689,23.2968176 C14.47981,22.358441 14.47981,20.841679 13.5510689,19.9033024 L8.10451306,14.40024 L35.6247031,14.40024 C36.9382423,14.40024 38,13.3274668 38,12.0003 C38,10.6731332 36.9382423,9.60035999 35.6247031,9.60035999"
      />
    </defs>
    <use fillRule="evenodd" xlinkHref="#arrow-left-a" />
  </svg>
);

export const RoundCheck = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51" {...props}>
    <circle cx="25.5" cy="25.5" r="25.5" fill="#00c05f" />
    <path
      d="M7,15.748l-7-7,7,7L21.5,0,7,15.748l0,0,0,0-.008.009Z"
      transform="translate(14.75 17.5)"
      fill="none"
      stroke="#fff"
      strokeWidth="1"
    />
  </svg>
);

export const Check = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" {...props}>
    <defs>
      <path
        id="check-a"
        d="M9.1406,18.9997 C8.8846,18.9997 8.6286,18.9017 8.4336,18.7067 L4.1526,14.4257 C3.7616,14.0347 3.7616,13.4017 4.1526,13.0117 C4.5426,12.6207 5.1756,12.6207 5.5666,13.0117 L9.1406,16.5857 L18.4336,7.2927 C18.8246,6.9017 19.4566,6.9017 19.8476,7.2927 C20.2386,7.6837 20.2386,8.3157 19.8476,8.7067 L9.8476,18.7067 C9.6526,18.9017 9.3966,18.9997 9.1406,18.9997"
      />
    </defs>
    <use fillRule="evenodd" transform="translate(-3 -7)" xlinkHref="#check-a" />
  </svg>
);
