import styled, { css } from 'styled-components';

import { RoundCheck } from '../../assets';

export const ConfirmationContainer = styled.div`
  text-align: center;

  ${({ theme }) =>
    theme.currentMediaConfig === 'large'
      ? css`
          padding: 2.5rem;

          p {
            font-size: 1.25rem;
            margin: 0 auto;
            max-width: 48rem;
          }
        `
      : css`
          padding: 0 2.5rem;
        `}
`;

export const IconCheck = styled(RoundCheck)`
  margin: 3rem 0;

  ${({ theme }) =>
    theme.currentMediaConfig === 'large'
      ? css`
          height: 6rem;
          width: 6rem;
        `
      : css`
          height: 4rem;
          width: 4rem;
        `}
`;
