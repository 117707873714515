import styled from 'styled-components';

import dot from '../../../assets/dot.svg';
import { Check } from '../../../assets';

export const ProgressBarContainer = styled.div`
  margin: 0 auto 3rem;
  width: ${({ steps }) => steps.length * 8}rem;
`;

export const Filler = styled.div`
  background: url(${dot});
  height: 0.25rem;
  position: relative;
  top: 0.5rem;
  width: 100%;

  &::after {
    background: #007ab3;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    transition: all 1.25s ease-in-out;
    width: ${({ percentage }) => percentage}%;
  }
`;

export const StepIndicatorsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CheckIcon = styled(Check)`
  fill: #fff;
  height: 0.375rem;
  left: 0;
  position: absolute;
  top: 0.0625rem;
  transition: all 0.5s ease-in;
  width: 0.5rem;
`;

export const StepIndicatorRounded = styled.div`
  background-color: #fff;
  border-radius: 50%;
  border: 0.125rem solid #007ab3;
  height: 0.75rem;
  position: relative;
  width: 0.75rem;

  svg {
    opacity: 0;
  }

  &.checked {
    background-color: #007ab3;

    svg {
      opacity: 1;
    }
  }

  &::after {
    content: '${({ content }) => content}';
    font-size: 0.75rem;
    position: absolute;
    top: 1rem;
    transform: translateX(-50%);
    white-space: nowrap;
  }
`;
