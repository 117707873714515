import React, { Fragment } from 'react';

import { validateOnlyLetters } from '../utils/validations';
import Input from './Input';

const NameInputs = () => (
  <Fragment>
    <Input name="firstName" label="Prénom" validation={validateOnlyLetters} required />
    <Input name="lastName" label="Nom" validation={validateOnlyLetters} required />
  </Fragment>
);

export default NameInputs;
