import React from 'react';
import styled from 'styled-components';

import { ArrowBack } from '../../assets';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonSubmit = styled.button`
  background-color: #007ab3;
  border-radius: 0.25rem;
  color: #fff;
  display: block;
  font-size: 0.875rem;
  letter-spacing: 0.0625rem;
  padding: 1.25rem 3.5rem;
  text-transform: uppercase;
  transition: all 0.25s;

  &:disabled {
    background-color: lightGray;
    cursor: default;
  }
`;

export const ButtonBack = styled(props => (
  <button type="button" {...props}>
    <ArrowBack />
  </button>
))`
  align-items: center;
  border: 0.125rem solid #007ab3;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  padding: 0 2rem;
  transition: all 0.25s;

  svg {
    fill: #007ab3;
    height: 1rem;
    transition: all 0.25s;
    width: auto;
  }

  &:hover {
    background-color: #007ab3;

    svg {
      fill: #fff;
    }
  }
`;
