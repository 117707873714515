import styled, { css } from 'styled-components';

export const NoticeContainer = styled.div`
  margin: 1.5rem 0 2.5rem;
  text-align: left;
`;

export const Title = styled.h4`
  font-weight: 700;
  ${({ theme }) =>
    theme.currentMediaConfig === 'large' &&
    css`
      font-size: 1.125rem;
    `}
  margin: 0 0 0.5rem;

  ${({ required }) =>
    required &&
    css`
      &::after {
        color: #eb0045;
        content: '*';
        padding-left: 0.25rem;
      }
    `}
`;

export const Content = styled.p`
  font-style: italic;
  opacity: 0.875;
`;
